.news{
  .bgblock{
    position: absolute;
    left: 0;
    top: 40%;
    width: 100%;
    .words{
      max-width: 1280px;
      width: 100%;
      margin: 0 auto;
      transform: translateX(5%);
      padding: 0 20px;
      h1{
        color: #fff;
        font-size: 40px;
        padding-bottom: 10px;
        margin-bottom: 10px;
      }
      h2{
        color: #71706d;
        font-size: 40px;
        text-indent: 2em;
        margin: 0;
      }
    }
  }
  .newsList{
    width: 100%;
    list-style: none;
    display: flex;
    flex-wrap:wrap;
    padding-top: 20px;
    li{
      max-width: 25%;
      padding: 25px;
      cursor: pointer;
      transition: all 300ms linear;
      // border-radius: 8px;
      &:hover{
        box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
        background: #fff;
        img{
          transform: scale(1.1);
        }
      }
      div{
        width: 100%;
        height: auto;
        border: 5px solid #ccc;
        overflow: hidden;
      }
      img{
        transition: all 300ms linear;
        max-width: 100%;
      }
      h4{
        font-size: 20px;
        margin-top: 10px;
        padding-bottom: 10px;
        color: #627792;
        line-height: 1.4;
        height: 60px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
      p{
        font-size: 14px;
        line-height: 1.5;
        color: #627792;
        height: 62px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
      }
    }
  }
}