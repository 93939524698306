.bgimg{
  max-height: 100%;
  display: block;
  margin: 0 auto;
}
.bg{
  position: relative;
  // background: #078af9;
  // height: auto;
  max-height: 504px;
  height: 504px;
  // min-height: 300px;
  overflow: hidden;
  .bgblock{
    position: absolute;
    left: 0;
    top: 40%;
    width: 100%;
    .words{
      max-width: 1280px;
      width: 100%;
      margin: 0 auto;
      transform: translateX(5%);
      padding: 0 20px;
      h1{
        color: #fff;
        font-size: 40px;
        padding-bottom: 10px;
        margin-bottom: 10px;
      }
      h2{
        color: #71706d;
        font-size: 40px;
        text-indent: 2em;
        margin: 0;
        line-height: 1.1;
        text-transform: uppercase;
      }
    }
  }
}
.menus{
  max-width: 1280px;
  width: 100%;
  margin: 0 auto;
  height: 50px;
  display: flex;
  padding: 0 15px;
  .logo{
    width: 139px;
    height: 46px;
    margin-right: 50px;
  }
  .menusDetail{
    flex: 1;
    background: none;
    border-bottom: none;
    .ant-menu-item{
      color: #fff;
      a{
        color: #fff;
      }
      position: relative;
      margin: 0 5px;
    }
    .submenu-title-wrapper{
      color: #fff;
      a{
        color: #fff;
      }
    }
    .ant-menu-item, .ant-menu-submenu{
      margin: 0 5px;
      border-bottom: none !important;
      position: relative;
      &:after{
        transition: all linear 300ms;
        position: absolute;
        left: 0;
        top: 15%;
        content: '';
        width: 100%;
        height: 70%;
        background: linear-gradient( 90deg, #05c8d1, #078af9);
        z-index: -1;
        border-radius: 40px;
        opacity: 0;
      }
      &:hover{
        a{
          color: #fff;
        }
        &:after{
          opacity: 1;
        }
      }
    }
    .ant-menu-item-active, .ant-menu-submenu-active{
      border-bottom: none !important;
    }
    .ant-menu-item-selected, .ant-menu-submenu-selected, .ant-menu-submenu-open{
      &:after{
        opacity: 1;
        color: #fff;    
      }
    }
  }
  .reg{
    width: 150px;
    color: #fff;
    line-height: 50px;
    text-align: right;
  }
  &.fixed{
    .reg{
      color: #344994;
    }
    .ant-menu-item{
      color: #344994;
      a{
        color: #344994;
      }
    }
    .submenu-title-wrapper{
      color: #344994;
      a{
        color: #344994;
      }
    }
    .ant-menu-item-selected, .ant-menu-submenu-selected .submenu-title-wrapper, .ant-menu-item-active, .ant-menu-submenu-active .submenu-title-wrapper{
      a{
        color: #fff;
      }
      color: #fff;
    }
  }
}

.ant-menu.ant-menu-sub {
  background:none;
}
.ant-menu-submenu-popup{
  background: rgba(255, 255, 255, 1);
}

.fixMenus{
  left: 0;
  top: 0;
  padding-top: 20px;
  width: 100%;
  height: 85px;
  background: rgba(255, 255, 255, 0.9);
  z-index: 99;
  position: relative;
  position: fixed;
  transition: transform 300ms linear;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
}
.absMenus{
  width: 100%;
  position: absolute;
  left: 50%;
  top: 20px;
  transform: translateX(-50%);
}
.home{
  .indexContent{
    max-width: 100%;
    padding: 60px 30px 30px 30px;
    p{
      font-size: 18px;
      line-height: 1.5;
      strong{
        color: #344994;
        font-size: 1.3em;
      }
    }
    h3{
      font-size: 36px;
      text-align: center;
      padding-top: 35px;
      line-height: 1.5;
    }
    .img{
      text-align: center;
      padding: 30px 0 0 0;
      img{
        max-width: 60%;
      }
    }
  }
  .product{
    background-image: url('./../images/ibg2.jpg');
    background-size: 100% auto;
    background-position: bottom left;
    background-repeat: no-repeat;
    background-color: #0c1f47;
    height: 620px;
  }
  .pList{
    list-style: none;
    display: flex;
    padding: 0 20px 0 20px;
    li{
      flex: 1;
      text-align: center;
      cursor: pointer;
      img{
        max-width: 70%;
        transition: all 300ms linear;
        &:hover{
          // transform: scale(1.1);
          transform: translateY(-10px);
        }
      }
      h4{
        color: #fff;
        font-size: 26px;
        padding: 20px 0 0 0;
      }
    }
  }
  .picture{
    text-align: center;
    img{
      max-width: 100%;
    }
  }

  .question{
    background-image: url('./../images/ibg.png');
    background-size: 100% auto;
    background-position: bottom left;
    background-repeat: no-repeat;
    background-color: #0c1f47;
    height: 350px;
    .picture{
      padding-top: 60px;
      text-decoration: none;
      img{
        transition: all 300ms linear;
        cursor: pointer;
        &:hover{
          transform: scale(1.05);
        }
      }
    }
  }
  .newsContent{
    background-color: #fafafa;
  }
  .newsList{
    list-style: none;
    padding-top: 30px;
    padding-bottom: 50px;
    li{
      display: flex;
      // background-color: #fff;
      border-radius: 10px;
      padding: 20px 0 20px 0;
      transition: all 300ms linear;
      margin-bottom: 20px;
      cursor: pointer;
      &:hover{
        box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
        background: #fff;
      }
      span{
        width: 120px;
        text-align: center;
        font-size: 16px;
        color: #627792;
        b{
          font-size: 1.5em;
          display: block;
        }
      }
      div{
        flex: 1;
        border-left: 1px solid #eaecef;
        padding-left: 50px;
        padding-right: 40px;
        h4{
          font-size: 22px;
          padding-bottom: 10px;
          color: #627792;
        }
        p{
          font-size: 16px;
          line-height: 1.5;
          color: #627792;
        }
      }
    }
  }
  .researchList{
    list-style: none;
    padding-top: 30px;
    padding-bottom: 50px;
    li{
      display: flex;
      padding: 20px;
      transition: all 300ms linear;
      margin-bottom: 20px;
      cursor: pointer;
      border-radius: 8px;
      &:hover{
        // box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
        // background: #fff;
        img{
          transform: scale(1.1);
        }
        h4, p{
          text-decoration: underline;
        }
      }
      img{
        max-width: 100%;
        border: 5px solid #ccc;
        transition: all 300ms linear;
      }
      div{
        flex: 1;
        // padding: 0 50px;
        h4{
          font-size: 22px;
          padding-bottom: 10px;
          color: #627792;
        }
        p{
          font-size: 16px;
          line-height: 1.5;
          color: #627792;
        }
      }
      &:nth-child(odd){
        div{
          padding: 0 0 0 50px;
        }
      }
      &:nth-child(even){
        div{
          padding: 0 50px 0 0;
        }
      }
    }
  }
  .risk{
    background-image: url(./../images/home-m1.jpg);
    background-position: bottom center;
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    p{
      color: #344994;
      font-size: 18px;
      margin: 0 15%;
      background: rgba(255, 255, 255, 0.7);
      padding: 20px 35px;
      line-height: 1.5;
      // border: 5px solid #fff;
    }
    h3{
      // flex: 1;
      color: #344994;
      text-align: center;
      font-size: 36px;
      font-weight: bold;
      background: rgba(255, 255, 255, 0.7);
      padding: 20px 35px;
      border: 5px solid #fff;
    }
  }
  .product2{
    background: #efefef;
    padding: 30px;
    display: flex;
    align-items: center;
    .colms1{
      display: flex;
      margin: 0 25px;
      .iconbox{
        font-size: 52px;
        background: #078af9;
        color: #fff;
        height: 100px;
        width: 100px;
        min-width: 100px;
        border-top-left-radius: 70px;
        border-bottom-left-radius: 70px;
        border-bottom-right-radius: 70px;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: all 300ms linear;
        &:hover{
          transform: scale(1.2);
        }
      }
      .iconbox2{
        font-size: 52px;
        background: #1689a0;
        color: #fff;
        height: 100px;
        width: 100px;
        min-width: 100px;
        border-top-left-radius: 70px;
        border-bottom-left-radius: 70px;
        border-top-right-radius: 70px;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: all 300ms linear;
        &:hover{
          transform: scale(1.2);
        }
      }
      .text{
        margin-left: 50px;
        h5{
          font-size: 26px;
          font-weight: bold;
        }
        p{
          font-size: 18px;
          line-height: 1.4;
        }
      }
    }
  }

  .jiazhi{
    display: flex;
    .contcolm{
      width: 60%;
      padding-top: 20px;
      .cbox{
        display: flex;
        margin-bottom: 60px;
        margin-top: 20px;
        .iconbox3{
          font-size: 52px;
          background: #1689a0;
          color: #fff;
          height: 100px;
          width: 100px;
          min-width: 100px;
          border-top-left-radius: 70px;
          border-bottom-left-radius: 70px;
          border-bottom-right-radius: 70px;
          display: flex;
          justify-content: center;
          align-items: center;
          transition: all 300ms linear;
          &:hover{
            transform: scale(1.2);
          }
        }
        .text{
          margin-left: 45px;
          h5{
            font-size: 18px;
            line-height: 1.4;
            font-weight: bold;
          }
          p{
            font-size: 14px;
            padding: 0;
            margin: 0;
          }
        }
      }
    }
    .imgcolm{
      width: 35%;
      margin-left: 5%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      img{
        max-width: 90%;
      }
      h4{
        text-align: center;
        font-size: 18px;
        font-weight: bold;
      }
    }
  }
}
