.risk{
  .bgblock{
    position: absolute;
    left: 0;
    top: 40%;
    width: 100%;
    .words{
      max-width: 1280px;
      width: 100%;
      margin: 0 auto;
      transform: translateX(5%);
      padding: 0 20px;
      // h1{
      //   color: #fff;
      //   font-size: 40px;
      //   padding-bottom: 10px;
      //   margin-bottom: 10px;
      //   text-align: right;
      //   padding-right: 6em;
      //   // text-indent: 18em;
      // }
      // h2{
      //   color: #71706d;
      //   font-size: 40px;
      //   // text-indent: 20em;
      //   margin: 0;
      //   text-align: right;
      //   padding-right: 4em;
      // }
      h1{
        color: #fff;
        font-size: 40px;
        padding-bottom: 10px;
        margin-bottom: 10px;
      }
      h2{
        color: #71706d;
        font-size: 40px;
        text-indent: 2em;
        margin: 0;
        text-transform: uppercase;
      }
    }
  }
  .contentWord{
    padding: 30px 0 0 0;
    p{
      font-size: 16px;
      line-height: 2;
    }
    h3{
      font-size: 36px;
      text-align: center;
      padding-top: 15px;
      line-height: 1.5;
    }
    h4{
      font-size: 18px;
      text-align: center;
    }
    .img{
      img{
        display: block;
        margin: 0 auto;
        max-width: 90%;
      }
      .r2{
        background: #fff;
        border: 30px solid #fff;
        margin-top: 20px;
      }
    }
    .infoDetail2{
      display: flex;
      margin: 0 10%;
      div{
        flex: 1;
        padding: 0 20px;
        margin-top: 20px;
        padding-bottom: 30px;
        img{
          display: block;
          margin: 0 auto 20px auto;
          transition: all 300ms linear;
          &:hover{
            transform: scale(1.2);
          }
        }
        h4{
          font-size: 18px;
          font-weight: bold;
          // text-align: center;
          color: #344994;
          // padding-bottom: 20px;
          text-align: center;
          // border-bottom: 2px solid #344994;
        }
        p{
          font-size: 14px;
          line-height: 1.5;
          margin-bottom: 0;
          color: #627792;
          text-align: center;
          padding: 5px 0;
        }
      }
    }
    .infoDetail{
      display: flex;
      margin: 0 10%;
      div{
        flex: 1;
        padding: 0 20px;
        margin-top: 20px;
        padding-bottom: 30px;
        h4{
          font-size: 18px;
          font-weight: bold;
          text-align: center;
          color: #344994;
          padding-bottom: 20px;
          border-bottom: 2px solid #344994;
        }
        p{
          font-size: 14px;
          line-height: 1.5;
          margin-bottom: 0;
          color: #627792;
          display: flex;
          // border-bottom: 1px solid #ccc;
          padding: 15px 10px;
          span{
            width: 180px;
            font-weight: bold;
          }
          b{
            flex: 1;
            font-weight: normal;
          }
        }
      }
    }
    .quote-title{
      margin: 40px 0 30px 0;
    }
    .quote{
      background: #fff;
      border-radius: 30px;
      padding: 20px 20px;
      max-width: 80%;
      margin: 0 auto;
      p{
        line-height: 1.2;
        padding: 5px 15px;
        font-size: 14px;
        margin-bottom: 0;
        b{
          font-size: 18px;
        }
        strong{
          color: #344994;
        }
      }
      .risk3{
        float: right;
        max-width: 40%;
        margin-left: 20px;
      }
    }
    .addcontent{
      display: flex;
      align-items: center;
      margin-top: 30px;
      padding: 30px 30px 0 30px;
      border-radius: 8px;
      background-color: rgba(255, 255, 255, 0.2);
      .c1{
        flex: 1;
        h4{
          text-align: left;
          font-weight: bold;
          margin-bottom: 1em;
        }
        h5{
          color: #344994;
          background: #efefef;
          padding: 5px 20px;
          font-size: 16px;
          border-top-right-radius: 20px;
          border-bottom-left-radius: 20px;
          border-bottom-right-radius: 20px;
        }
        ul{
          list-style: none;
          li{
            color: #fff;
            position: relative;
            padding-left: 20px;
            &::before{
              width: 2px;
              height: 2px;
              background: #fff;
              position: absolute;
              left: 5px;
              top: 10px;
              content: '';
            }
          }
        }
      }
      .c2{
        width: 180px;
        font-size: 36px;
        color: #fff;
        text-align: center;
        // font-weight: bold;
      }
      .c3{
        .c3content{
          display: flex;
          margin: 20px 0;
          align-items: center;
          // justify-content: center;
          .text{
            width: 100px;
            height: 100px;
            background: #fff;
            border-radius: 60px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 26px;
            padding: 0 20px;
            text-align: center;
            line-height: 1.2;
            font-weight: bold;
            margin-right: 20px;
          }
          .icon{
            color: #fff;
            font-size: 36px;
          }
        }
      }
    }
    .pingce{
      padding: 30px 20%;
      line-height: 1.5;
    }
  }
  .contentbg{
    background: #1689a0;
    padding-bottom: 30px;
    h3, h4{
      color: #fff;
    }
  }

  .jiazhi{
    display: flex;
    .contcolm{
      width: 60%;
      padding-top: 20px;
      .cbox{
        display: flex;
        margin-bottom: 60px;
        .iconbox3{
          font-size: 52px;
          background: #1689a0;
          color: #fff;
          height: 100px;
          width: 100px;
          min-width: 100px;
          border-top-left-radius: 70px;
          border-bottom-left-radius: 70px;
          border-bottom-right-radius: 70px;
          display: flex;
          justify-content: center;
          align-items: center;
          transition: all 300ms linear;
          &:hover{
            transform: scale(1.2);
          }
        }
        .text{
          margin-left: 45px;
          h5{
            font-size: 18px;
            line-height: 1.4;
            font-weight: bold;
          }
          p{
            font-size: 14px;
            padding: 0;
            margin: 0;
          }
        }
      }
    }
    .imgcolm{
      width: 35%;
      margin-left: 5%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      img{
        max-width: 90%;
      }
      h4{
        text-align: center;
        font-size: 18px;
        font-weight: bold;
      }
    }
  }
}