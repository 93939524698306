.ins{
  .bgblock{
    position: absolute;
    left: 0;
    top: 40%;
    width: 100%;
    .words{
      max-width: 1280px;
      width: 100%;
      margin: 0 auto;
      transform: translateX(5%);
      padding: 0 20px;
      h1{
        color: #fff;
        font-size: 40px;
        padding-bottom: 10px;
        margin-bottom: 10px;
      }
      h2{
        color: #71706d;
        font-size: 40px;
        text-indent: 2em;
        margin: 0;
        text-transform: uppercase;
      }
    }
  }
  .contengBg{
    background: #344994;
    h3{
      color: #fff;
    }
  }
  .contentWord.top1{
    padding-top: 60px;
  }
  .contentWord{
    padding: 30px 0 0 0;
    p{
      font-size: 16px;
      line-height: 2;
    }
    h3{
      font-size: 36px;
      text-align: center;
      padding-top: 35px;
      line-height: 1.5;
    }
  }
  .infoDetail{
    display: flex;
    color: #fff;
    div{
      flex: 1;
      padding: 0 20px;
      margin-top: 20px;
      padding-bottom: 30px;
      h4{
        font-size: 18px;
        font-weight: bold;
        text-align: center;
        // color: #344994;
        color: #fff;
        padding-bottom: 20px;
        border-bottom: 2px solid #344994;
      }
      p{
        font-size: 14px;
        line-height: 1.5;
        margin-bottom: 0;
        // color: #627792;
        display: flex;
        border-bottom: 1px solid #ccc;
        padding: 15px 10px;
        span{
          width: 180px;
          font-weight: bold;
        }
        b{
          flex: 1;
          font-weight: normal;
        }
        &:last-child{
          border-bottom: none;
        }
      }
    }
  }
  .service{
    padding-bottom: 30px;
    h4{
      font-weight: bold;
      font-size: 18px;
      color: #344994;
      margin-top: 30px;
    }
    h5{
      font-size: 16px;
    }
    .pic{
      padding: 20px 0;
      img{
        max-width: 90%;
      }
    }
    .plan{
      display: flex;
      flex-wrap: wrap;
      li{
        width: 33%;
        color: #627792;
        font-size: 14px;
        padding: 20px 40px 20px 0;
        // border-right: 1px solid #627792;
        div{
          font-size: 16px;
          font-weight: bold;
          padding-bottom: 20px;
          i{
            font-size: 36px;
            margin-right: 20px;
          }
        }
        p{
          line-height: 1.5;
          font-size: 14px;
        }
      }
    }
    .scope{
      margin: 30px auto 0 auto;
      // margin-top: 30px;
      max-width: 90%;
      width: 90%;
      border-left:1px solid #71706d;
      border-top: 1px solid #71706d;
      td{
        padding: 10px 5px;
        overflow: hidden;
        text-align: center;
        border-right:1px solid #71706d;
        border-bottom: 1px solid #71706d;
        color: #627792;
        &.blue{
          background: #e9ecf1;
          color: #4276aa;
          font-weight: bold;
        }
      }
    }
  }
  .addressbg{
    background: #efefef;
  }
  .address{
    padding-bottom: 30px;
    // text-align: center;
    display: flex;
    align-items: center;
    div{
      flex: 1;
    }
    .img{
      text-align: right;
      padding-right: 15%;
      img{
        max-width: 75%;
      }
    }
    h4{
      font-weight: bold;
      font-size: 18px;
    }
    h5{
      font-size: 16px;
    }
  }
}