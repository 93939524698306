.about{
  .pb50{
    padding-bottom: 50px;
  }
  .bgblock{
    position: absolute;
    left: 0;
    top: 40%;
    width: 100%;
    .words{
      max-width: 1280px;
      width: 100%;
      margin: 0 auto;
      transform: translateX(5%);
      padding: 0 20px;
      h1{
        color: #fff;
        font-size: 40px;
        padding-bottom: 10px;
        margin-bottom: 10px;
      }
      h2{
        color: #71706d;
        font-size: 40px;
        text-indent: 2em;
        margin: 0;
      }
    }
  }
  .aboutFlex{
    display: flex;
    & > div{
      flex: 1;
      margin: 0 30px;
      h3{
        font-size: 24px;
        margin-bottom: 1em;
        color: #344994;
        border-bottom: 1px solid #344994;
        padding-bottom: 0.8em;
        font-weight: bold;
      }
    }
  }
  .address{
    padding: 30px 0 0 0;
    h5{
      font-size: 15px;
      margin-top: 20px;
      i{
        font-size: 15px;
        color: #344994;
        position: relative;
        top: 2px;
        margin-right: 10px;
      }
      img{
        width: 200px;
      }
    }
  }
  .reg{
    padding: 30px 0 0 0;
    input, textarea{
      margin-bottom: 15px;
    }
  }
  .aboutContent{
    padding: 30px 0 0 0;
    p{
      font-size: 18px;
      line-height: 2;
      strong{
        // color: #344994;
        // font-size: 1.3em;
        font-weight: normal;
      }
    }
  }
}