.footer{
  width: 100%;
  background: #344994;
  color: #fff;
  padding: 60px;
  text-align: center;
  a{
    color: #fff;
    text-transform: none;
  }
}