.research{
  .bgblock{
    position: absolute;
    left: 0;
    top: 40%;
    width: 100%;
    .words{
      max-width: 1280px;
      width: 100%;
      margin: 0 auto;
      transform: translateX(5%);
      padding: 0 20px;
      h1{
        color: #fff;
        font-size: 40px;
        padding-bottom: 10px;
        margin-bottom: 10px;
        text-align: right;
        padding-right: 6em;
        // text-indent: 18em;
      }
      h2{
        color: #71706d;
        font-size: 40px;
        // text-indent: 20em;
        margin: 0;
        text-align: right;
        padding-right: 4em;
      }
    }
  }
  .researchList{
    list-style: none;
    padding-top: 60px;
    padding-bottom: 40px;
    li{
      display: flex;
      padding: 20px;
      transition: all 300ms linear;
      margin-bottom: 20px;
      cursor: pointer;
      border-radius: 8px;
      &:hover{
        // box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
        // background: #fff;
        img{
          transform: scale(1.1);
        }
        h4, p{
          text-decoration: underline;
        }
      }
      img{
        max-width: 100%;
        transition: all 300ms linear;
        display: block;
      }
      span{
        width: 300px;
        height: auto;
        overflow: hidden;
        border: 5px solid #ccc;
      }
      div{
        flex: 1;
        // padding: 0 50px;
        h4{
          font-size: 22px;
          padding-bottom: 10px;
          color: #627792;
        }
        p{
          font-size: 16px;
          line-height: 1.5;
          color: #627792;
        }
      }
      &:nth-child(odd){
        div{
          padding: 0 0 0 50px;
        }
      }
      &:nth-child(even){
        div{
          padding: 0 50px 0 0;
        }
      }
    }
  }
}